<template>
   <div>
        <v-tab-item v-for="(itemtab, i) in itemTabs" :key="`${i}-${itemtab.id}`">
        <v-list dense subheader v-for="(item, j) in categoriasObtenidasFiltrada"
          :key="`${j}-${item.id}`" :id="item.categoria">
          <v-subheader 
            class="text-uppercase body-2 font-weight-black font-italic item"           >
            <v-icon :color=colorMenu class="ma-0 pa-0">mdi-menu-right</v-icon>
            {{ item.categoria }}      
          </v-subheader>
                 <v-list-item
                      dense
                      v-for="(subitem, k) in filtroPorCategorias(item.id)"
                      :key="`${k}-${subitem.id}`"
                      class="itempar"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          class="caption"
                          v-text="subitem.item"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="subitem.descripcion"
                        ></v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-text="subitem.descripcion2"
                        ></v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-text="subitem.descripcion3"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text v-text="`${moneda} ` + `${subitem.precio}`">
                        </v-list-item-action-text>
                      </v-list-item-action>
                      <v-list-item-action v-if="subitem.preciosocio">             
                        <v-list-item-action-text
                          v-text="`RC ` + `${moneda} ` + `${subitem.preciosocio}`"
                        >
                        </v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>
                    <v-card class="mt-2 mb-4 mx-4 pa-2 caption text-center" elevation-1 :color=colorMenu dark v-show="item.pie">                      
                      <span v-html="item.pie"></span>                         
                    </v-card>
                    <v-divider></v-divider>
                    </v-list>
                    <v-card class="mb-4 mx-4 pa-2 caption text-center " dark :color=colorMenu elevation-1 v-if="piegeneral">
                      <span v-html="piegeneral"></span>
                    </v-card>                  
                  </v-tab-item>
          </div>  
</template>

<script>
export default {
  name: 'listarItemsCarta',
  props: {
        categoriasObtenidasFiltrada: Array,
        cartaObtenida: Array,
        itemTabs: Array,
        colorMenu: String,
        moneda: String,
        piegeneral: String
  },
  data() {
           return {
             
                     
           }

  },
  methods: {
    filtroPorCategorias(id) {
                                    let resultado = [];
                                    resultado = this.cartaObtenida.filter(
                                      categoria => categoria.idcategoria === id
                                    );
                              //     alert(colorMenu)
                                    return resultado;
                                  }
        
  }
  
}
</script>
<style scoped>
.itempar:nth-child(odd) {
  background-color: #f7f7f7;
}
.v-tabs-bar.v-tabs-bar--is-mobile .v-tab {
  margin: 0px !important;
  padding: 0px !important;
  min-width: 0;
}

.item {
  margin: 0px;
  padding: 0px;
  border-color: green;
  border-width: 1px;
  height: 25px;
  color: green !important;
}

</style>
