<template>
  <v-container fluid>
    <!--HEADER-->
    <v-app-bar :color=colorMenu flat app dense height="40px" dark>
      <v-btn icon :to="{ name: complejo }">
        <v-icon>mdi-home-outline</v-icon>
      </v-btn>
      <v-toolbar-title>{{ tituloHeader }}</v-toolbar-title>
    
      <template v-slot:extension>
        
        <v-tabs
          dense
          icons-and-text
          dark
          grow
          show-arrows
          centered
          v-show="mostrarTab"
          v-model="tabs"
          color="white"
        >
          <v-tab
            dense
            class="caption"
            v-for="(itema, a) in itemTabs"
            :key="`${a}-${itema.id}`"
            @click="filtroPorTipo(itema.idpadre)"
          >
            <v-row dense>
              {{ itema.descripcion }}
           <br>
              {{ itema.descripcion2 }}
            </v-row>           
            <v-icon dense>{{ itema.icono }}</v-icon>
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    
    <v-tabs-items v-model="tabs" @change="cambio(tabs)">
           <listarItemsCarta  :piegeneral=piegeneral :moneda=moneda :itemTabs=itemTabs :categoriasObtenidasFiltrada=categoriasObtenidasFiltrada :colorMenu=colorMenu :cartaObtenida=cartaObtenida> </listarItemsCarta>
    </v-tabs-items>
  </v-container>
</template>

<script>

import listarItemsCarta from '../components/listarItemsCarta'
import axios from 'axios'
export default {
  components: { listarItemsCarta},
  name: 'cartacomplejo',
  props: {
            tituloHeader: String,
            idcomplejo: Number,
            complejo: String,
            menu: Number,
            colorMenu: String,
            moneda: String,
            piegeneral: String,
  },
  data() {
    return {
   
      mostrarTab: "false",
      activeBtn: 1,
      tabs: null,
      showNav: true,
      cartaObtenida: [],
      categoriasObtenidas: [],
      vinosObtenidos: [],
      categoriasObtenidasFiltrada: [],
      cartaObtenidaFiltrada: [],
      itemTabs:[],
      buscar: "",     
    };
  },
  created: async function() {
         await axios.get(process.env.VUE_APP_API +'/complejo/menu_tabs',
             {params:{
                   idcomplejo: this.idcomplejo,
                   menu: this.menu
                    }
        })
      .then((response) => {
                          this.itemTabs= response.data
                      
      })
         axios.get(process.env.VUE_APP_API +'/complejo/carta',
             {params:{
                   idcomplejo: this.idcomplejo
                    }
        })
      .then((response) => {
                          this.cartaObtenida= response.data
                        this.cartaObtenidaFiltrada=response.data
      })
       await axios.get(process.env.VUE_APP_API +'/complejo/cartacategorias',
              {params:{
                  idcomplejo: this.idcomplejo
                     }
          })
             .then((response) => {
                                 this.categoriasObtenidas= response.data
                              this.categoriasObtenidasFiltrada=this.categoriasObtenidas
       })
       this.cambio(0)   
  },
  
  methods: {
      
    goto(id) {
      let destino = "#" + id;
      this.$vuetify.goTo(destino);
    },
    cambio(item) {
      this.filtroPorTipo(this.itemTabs[item].idpadre);
    },
    busca() {
      this.buscar = "";
      this.mostrarTab = !this.mostrarTab;
    },
    filtroPorTab(id) {
      this.filtroPorTipo(id);
    },
    filtroPorCarta(id) {
      this.cartaObtenidaFiltrada = this.cartaObtenida.filter(
        categoria => categoria.idcategoria === id
      );
    },
    filtroPorTipo(id) {
      this.categoriasObtenidasFiltrada = this.categoriasObtenidas.filter(
        idpadre => idpadre.idpadre === id
      );
    },
    
  }
};
</script>
